import HeaderLinkModel from 'Models/Headers/HeaderLinkModel.interface';
import { useEffect, useState } from 'react';
import { styled } from 'Theme/stitches.config';
import { animation, timings } from 'DesignSystem/Animations/animation';
import NavigationOverlay from '../NavigationOverlay/NavigationOverlay';
import MenuItem from './Components/Items/MenuItem';
import BackLink from './Components/Items/BackItem';
import sizes from 'Theme/Settings/sizes';


import { useTranslations } from '../../../../context/init-data.context';

export type MenuPropTypes = {
  isOpen: boolean;
  menuLinks: HeaderLinkModel[];
  staticLinks: HeaderLinkModel[];
};

function Menu({ menuLinks, staticLinks, isOpen }: MenuPropTypes) {
  const [links, setLinks] = useState([menuLinks]);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setLinks([menuLinks]);
  }, [menuLinks]);

  const goBack = () => {
    setIsAnimating(true);
    setLinks((prev) => prev.slice(0, -1));
  };

  const traverse = (subLinks: any[]) => {
    setIsAnimating(true);
    setLinks((prev) => [...prev, subLinks]);
  };

  const currentMenu = links[links.length - 1];
  const topLevel = links.length === 1;
  const type = topLevel ? 'regular' : 'secondary';
  const {
    commonLabels: { back },
  } = useTranslations();

  return (
    <>
      <Root isOpen={isOpen}>
        <Wrapper
          type={type}
          css={{
            maxH: topLevel
              ? sizes.mainMobileMenuMaxHeight
              : sizes.subMobileMenuMaxHeight,
          }}
        >
          {links.length > 1 && <BackLink text={back} onClick={goBack} />}
          {currentMenu.map((item: HeaderLinkModel, index: number) => (
            <MenuItem
              key={index}
              item={item}
              hasAnimation={isAnimating}
              type={type}
              onClick={() => traverse(item.subLinks)}
            />
          ))}
          {topLevel && (
            <List>
              {staticLinks &&
                staticLinks.map((link, index: number) => (
                  <MenuItem key={index} type="secondary" item={link} />
                ))}
            </List>
          )}
        </Wrapper>
      </Root>
      {isOpen && <NavigationOverlay isVisible={isOpen} />}
    </>
  );
}

const Root = styled('div', {
  wh: '100%',
  transform: 'translateX(-100%)',
  position: 'fixed',
  zIndex: '$Flyout',
  backgroundColor: '$elevateOne',
  t: '$mobileHeaderUpperHeight',
  transition: `all ${timings.threeTenths} ${animation.timingFn}`,
  variants: {
    isOpen: {
      true: {
        transform: 'translateX(0)',
      },
    },
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  wh: '100%',
  overflowY: 'auto',
  variants: {
    type: {
      regular: {
        g: '$s100',
      },
      secondary: {
        g: 0,
      },
    },
  },
});

const List = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
});

export default Menu;
